import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl} from '@angular/forms';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from './dateFormat';
import { CustomDatepickerI18n, I18n } from './datepicker-i18n';
import {Index} from '@app/modules/customer-zone/consumption/models/consumption.interface';
import {Contract} from '@app/modules/customer-zone/contracts/models/contract.interface';

@Component({
  selector: 'fc-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class InputDateComponent implements OnInit {
  @Input() editIndex?: {metering: Index; contract: Contract};
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;

  public minDateStruct: NgbDateStruct = null;
  public maxDateStruct: NgbDateStruct = null;

  constructor() {}

  ngOnInit(): void {
    let defaultValue = this.control.value;
    if (this.editIndex) {
      this.control.disable();
      defaultValue = this.editIndex.metering.indexDate;
    }
    defaultValue = defaultValue instanceof Date ? defaultValue : new Date(defaultValue);
    this.control.setValue({
      day: defaultValue.getDate(),
      month: defaultValue.getMonth() + 1,
      year: defaultValue.getFullYear(),
    });

    if (this.minDate) {
      this.minDateStruct = {
        day: this.minDate.getDate(),
        month: this.minDate.getMonth() + 1,
        year: this.minDate.getFullYear(),
      };
    }

    if (this.maxDate) {
      this.maxDateStruct = {
        day: this.maxDate.getDate(),
        month: this.maxDate.getMonth() + 1,
        year: this.maxDate.getFullYear(),
      };
    }
  }
}
